// import { NavLink, Link } from "react-router-dom";
import Container from "react-bootstrap/Container";

import Innertopbanner from "../components/inner-topbanner/Innertopbanner";
import DataFeatures from "../components/data-features/DataFeatures";
import Services from "../components/services/Services";
import Demobanner from "../components/demobanner/Demobanner";

import SupplyDemand from "../images/product/navigate_earth-3.svg";
import SatelliteData from "../images/product/navigate_earth-2.svg";
import Miningdata from "../images/product/navigate_earth-1.svg";
// import Earth_mp from "../images/inner-banner/Earth.svg";
import Earth_mp from "../images/inner-banner/Earth1.svg";

import React, { useEffect } from "react";
const NavigateEarth = () => {
  useEffect(() => {
    window?.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, []);

  return (
    <section className="main">
      <section className="main-banner inner-banner">
        <Container className="text-center text-md-start">
          <Innertopbanner
            header={"Navigate Earth"}
            pradetails={
              "High-frequency thermal infrared satellite imagery monitoring intensity of heat signatures at metal smelters across the world, detecting activity, productivity & utilization of each facility."
            }
            image={
              <img
                className="innerbanner-img navigate_earth"
                src={Earth_mp}
                alt="banner_graphics"
              />
            }
          />
        </Container>
      </section>

      <section className="solution data-features py-4 text-center">
        <DataFeatures
          header={"Data & Features"}
          Pradetails={
            "Revolutionizing the Monitoring of Metal Smelting Activity with Real-Time Satellite Capabilities"
          }
        />
      </section>

      <section className="middle-top navigate_earth py-3">
        <Container className="text-cetner text-md-start">
          <Services
            image={SupplyDemand}
            header={"Smelter Inactivity"}
            pra={
              "Monitor which of your clients or competitors have idled (short-term), undergone maintenance (medium-term) or shut down (long-term) operations in real-time."
            }
          />
          <Services
            image={SatelliteData}
            header={"Productivity & Utilization"}
            pra={
              "Track production & capacity utilization rates of individual company-specific metal smelters and their site-specific equipment i.e. blast furnaces & sinter plants."
            }
          />
          <Services
            image={Miningdata}
            header={"Raw Materials Demand"}
            pra={
              "Derive real-time demand for raw materials based on high-frequency thermal infrared activity of your target metal smelter(s)."
            }
          />
        </Container>
      </section>

      <Demobanner />
    </section>
  );
};

export default NavigateEarth;
